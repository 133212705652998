// THESE ARE GLOBAL MIXINS THAT ARE AVAILABLE EVERYWHERE
// IN OUR CODE BASE. USE CAUTION WHEN ADDING NEW MIXINS TO
// AVOID POLLUTING THE GLOBAL NAMESPACE.

import moment from 'moment';
import axios from 'axios';

export default {
    data: function () {
        return {
            Node_JS_HOST: process.env.VUE_APP_ROOT_API,
            imageBaseDigitalOcean: 'https://static-assets-pi.sgp1.digitaloceanspaces.com/event-master',
            snackbarText:'',
            isShowSnackbar:false,
            axiosHeaders :{
                'X-Requested-With': 'XMLHttpRequest',
                'x-access-token': localStorage.getItem('token')
            },
            userDetail:{}
        };
    },
    methods: {
        showModal: function (modal) {
            this.$refs.modalRef.showModal(modal);
        },
        hideModal: function (modal) {
            this.$refs.modalRef.cancelClicked(modal);
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        dateFormat(date,formate = 'MMMM  D, YYYY'){
            return moment(date).format(formate)
        },
        makeToast(variant = null,msg) {
            this.$bvToast.toast(msg, {
                title: `Response`,
                variant: variant,
                solid: true
            })
        },
        logout(){
            axios.get('/api/logout')
                .then((res) => {
                    console.log(res);
                })
                .catch((error)=> {
                console.log(error);
                })
            localStorage.removeItem('token');
            this.$router.push('/');
        },
        errorHandle: function(error){
            var errorType = error.response.data.error;
            if(errorType == "Unauthorized"){
                localStorage.removeItem('token');
                this.$router.push('/');
            }
        },
        getImageBuffer: async function (imageName) {
            await axios.get(this.Node_JS_HOST + `/api/v1/web/banner-master/get-user-custom-file?fileName=${imageName}`)
              .then((res) => {
                const imageBuffer = res.data.imageBuffer
                return imageBuffer;
              })
              .catch((error) => {
                this.errorHandle(error);
              });
        },
    },
    mounted:function () {
    },
    created: function() {
        axios.defaults.headers.common['X-Requested-With'] =  'XMLHttpRequest';
        axios.defaults.headers.common['x-access-token'] = localStorage.getItem('token');
    },
};
