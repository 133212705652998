import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
    {
        path: '/dashboard',
        redirect: '/dashboard',
        component: () => import('@/layouts/Layout'),
        children: [
            // Components
            {
                name: 'PIAds',
                path: '/pi-ads',
                component: () => import('@/views/piAds/piAds'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'Googleads',
                path: '/google-ads',
                component: () => import('@/views/googleAds/Googleads'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'Applications',
                path: '/apps',
                component: () => import('@/views/applications/applications'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'Banner',
                path: '/banner-master/banner',
                component: () => import('@/views/bannerMaster/banner/Banner'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'Event',
                path: '/banner-master/event',
                component: () => import('@/views/bannerMaster/event/Event'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'UpcomingEvent',
                path: '/banner-master/upcoming-event',
                component: () => import('@/views/bannerMaster/upcomingEvent/UpcomingEvent'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'HomePageSliders',
                path: '/banner-master/home-page-sliders',
                component: () => import('@/views/bannerMaster/homePageSliders/HomePageSliders'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'FrameTester',
                path: '/banner-master/frame-tester',
                component: () => import('@/views/bannerMaster/FrameTester/Index'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'CustomUpload',
                path: '/banner-master/custom-upload',
                component: () => import('@/views/bannerMaster/customUpload/customUpload'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'Profile',
                path: '/profile',
                component: () => import('@/views/Profile'),
                meta: {
                    requiresAuth: true
                }
            },
            {
                name: 'ChangePassword',
                path: '/change-password',
                component: () => import('@/views/ChangePassword'),
                meta: {
                    requiresAuth: true
                }
            },
        ]
    },
    {
        name: 'Login',
        path: '/',
        component: () => import('@/views/Login/Login'),
        meta: {
            hideForAuth: true
        }
    },
    {
        path: '*',
        redirect: '/',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        let isAuthenticated = false;
        if (localStorage.getItem("token")) isAuthenticated = true;
        if (!isAuthenticated) {
            next('/')
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }

    if (to.matched.some(record => record.meta.hideForAuth)) {
        let isAuthenticated = false;
        if (localStorage.getItem("token")) isAuthenticated = true;
        if (isAuthenticated) {
            next({ path: '/pi-ads' });
        }
    }

})

export default router